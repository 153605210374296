import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Button, Modal } from 'react-daisyui';
import { twMerge } from 'tailwind-merge';
import { AppButton } from './AppButton';
import './AppModal.css';

export interface IAppModalProps {
  cancelButtonText?: string;
  children: ReactNode;
  containerClassName?: string;
  hasActionButtons?: boolean;
  hasCancelButton?: boolean;
  hasSubmitButton?: boolean;
  height?: 'lg' | 'md' | 'none' | 'sm' | 'xl' | 'xs';
  isActionLoading?: boolean;
  isCloseBackdropClick?: boolean;
  isOpen?: boolean;
  okButtonColor?: 'danger' | 'primary';
  okButtonText?: string;
  onCancelClick?: () => void;
  onOkClick?: () => void;
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  title?: any;
}

const AppModalSizes: Record<string, string> = {
  lg: 'max-w-3xl',
  md: 'max-w-md',
  sm: 'max-w-sm',
  xl: 'max-w-6xl',
  xs: 'max-w-xs',
};

const AppModalMinHeight: Record<string, string> = {
  lg: 'min-h-[580px]',
  md: 'min-h-[320px]',
  none: '',
  sm: 'min-h-[120px]',
  xl: 'min-h-[920px]',
  xs: 'min-h-[90px]',
};

export const AppModal = ({
  cancelButtonText = 'Cancel',
  children,
  containerClassName = '',
  hasActionButtons = true,
  hasCancelButton = false,
  hasSubmitButton = true,
  height = 'none',
  isActionLoading = false,
  isCloseBackdropClick = true,
  isOpen,
  okButtonColor = 'primary',
  okButtonText = 'Okay',
  onCancelClick,
  onOkClick,
  size = 'xs',
  title,
}: IAppModalProps) => {
  const sizeClassNames = AppModalSizes[size];
  const customHeight = AppModalMinHeight[height];

  if (!isOpen) return <></>;

  return (
    <Modal.Legacy
      className={twMerge(
        'overflow-visible break-words',
        sizeClassNames,
        customHeight,
        containerClassName,
      )}
      open={isOpen}
      onClickBackdrop={() => isCloseBackdropClick && onCancelClick?.()}
    >
      {title && (
        <Modal.Header className="flex mb-0 pb-5 items-start gap-[10px] self-stretch">
          <h3 className="w-full">{title}</h3>
        </Modal.Header>
      )}
      {onCancelClick && (
        <Button
          className="absolute top-2 right-2 w-10 h-10 drop-shadow-lg"
          color="ghost"
          shape="circle"
          size="sm"
          onClick={() => onCancelClick?.()}
        >
          <FontAwesomeIcon icon={faXmark} />
          <span className="sr-only">Close</span>
        </Button>
      )}

      <Modal.Body>{children}</Modal.Body>

      {hasActionButtons && (
        <Modal.Actions>
          <div
            className={twMerge(
              'grid w-full gap-4',
              clsx({
                'grid-cols-2': hasCancelButton && hasSubmitButton,
                'justify-center': !hasCancelButton || !hasSubmitButton,
              }),
            )}
          >
            {hasCancelButton && (
              <AppButton
                variant="outline"
                width={hasCancelButton ? 'full' : 'default'}
                onClick={() => onCancelClick?.()}
              >
                {cancelButtonText}
              </AppButton>
            )}
            {hasSubmitButton && (
              <AppButton
                color={okButtonColor}
                loading={isActionLoading}
                width={hasCancelButton ? 'full' : 'default'}
                onClick={() => onOkClick?.()}
              >
                {okButtonText}
              </AppButton>
            )}
          </div>
        </Modal.Actions>
      )}
    </Modal.Legacy>
  );
};
