import React, { ReactNode, Suspense, useEffect, useRef, useState } from 'react';
import { Drawer } from 'react-daisyui';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../App.css';
import ErrorModal from '../components/ErrorModal';
import { AppLoader } from '../components/Loader';
import AppHeaderV2 from '../components/navigation/AppHeaderV2';
import AppSidebar from '../components/navigation/AppSidebar';
import { reduxServices } from '../reducers/reduxServices';
import { useTypedSelector } from '../stateStore';

export interface IDefaultLayoutProps {
  children?: ReactNode;
  hasBreadcrumbs?: boolean;
  hasWorkspaceIcons?: boolean;
  initialCrumb?: string;
  mainWrapperBgColor?: 'neutral' | 'white';
  navbarElement?: ReactNode;
}

const usePreviousLocation = () => {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  return prevLocationRef.current;
};

const DefaultLayoutV3 = ({
  children,
  hasBreadcrumbs,
  initialCrumb,
  mainWrapperBgColor = 'neutral',
  navbarElement,
}: IDefaultLayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();

  const handleMenuToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const showErrorModal = useTypedSelector(
    reduxServices.globalState.selectors.showErrorModal,
  );

  const previousLocation = usePreviousLocation();
  const currentLocation = useLocation();

  useEffect(() => {
    if (previousLocation.pathname !== currentLocation.pathname) {
      dispatch(reduxServices.globalState.actions.setShowErrorPage(false));
    }
  }, [currentLocation, previousLocation, dispatch]);

  return (
    <Suspense fallback={<AppLoader />}>
      <Drawer
        className="lg:drawer-open"
        contentClassName="overflow-x-auto flex flex-col relative !z-auto"
        open={isSidebarOpen}
        side={<AppSidebar>{navbarElement}</AppSidebar>}
        onClickOverlay={handleMenuToggle}
      >
        <div className="overflow-y-hidden">
          <AppHeaderV2
            hasBreadcrumbs={hasBreadcrumbs}
            hasLogo={false}
            hasMenuToggle={true}
            initialCrumb={initialCrumb}
            onMenuToggle={handleMenuToggle}
          />
          <DefaultLayoutMainWrapper mainWrapperBgColor={mainWrapperBgColor}>
            {children ?? (
              <DefaultLayoutBody>
                <DefaultLayoutContent>
                  <Outlet />
                </DefaultLayoutContent>
              </DefaultLayoutBody>
            )}
            <ErrorModal isOpen={showErrorModal} />
          </DefaultLayoutMainWrapper>
        </div>
      </Drawer>
    </Suspense>
  );
};

export interface IDefaultLayoutMainWrapperProps {
  children: ReactNode;
  mainWrapperBgColor?: 'neutral' | 'white';
}

export const DefaultLayoutMainWrapper = ({
  children,
  mainWrapperBgColor = 'neutral',
}: IDefaultLayoutMainWrapperProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useTypedSelector(
    reduxServices.globalState.selectors.scrollToTop,
  );

  useEffect(() => {
    if (!contentRef.current || !scrollToTop) return;

    contentRef.current.scroll({
      behavior: 'smooth',
      top: 0,
    });
  }, [scrollToTop]);

  return (
    <main
      className={`h-[100vh] overflow-y-scroll ${
        mainWrapperBgColor === 'neutral' ? 'bg-neutral' : 'bg-sr-white'
      }`}
      ref={contentRef}
    >
      <div className="pb-36">{children}</div>
    </main>
  );
};

export interface IDefaultLayoutHeadingProps {
  children: ReactNode;
}

export const DefaultLayoutHeading = ({
  children,
}: IDefaultLayoutHeadingProps) => {
  return (
    <div className="px-5 py-5 w-full bg-white border-b border-sr-outline">
      <div className="text-center md:text-left xl:max-w-5xl xl:mx-auto 2xl:px-0">
        {children}
      </div>
    </div>
  );
};

export interface IDefaultLayoutBodyProps {
  children: ReactNode;
}

export const DefaultLayoutBody = ({ children }: IDefaultLayoutBodyProps) => {
  return (
    <div className="flex py-8 h-full md:px-5">
      <div className="flex flex-col w-full gap-1.5">{children}</div>
    </div>
  );
};

export interface IDefaultLayoutContentProps {
  children: ReactNode;
}

export const DefaultLayoutContent = ({
  children,
}: IDefaultLayoutContentProps) => {
  return (
    <div className="flex flex-col items-start self-stretch w-full h-auto gap-[30px] p-5 bg-white md:border md:border-sr-outline md:rounded-xl xl:max-w-screen-4xl xl:mx-auto">
      {children}
    </div>
  );
};

export default Object.assign(DefaultLayoutV3, {
  Body: DefaultLayoutBody,
  Content: DefaultLayoutContent,
  Heading: DefaultLayoutHeading,
  MainWrapper: DefaultLayoutMainWrapper,
});
