import { faBell, faNavicon } from '@fortawesome/free-solid-svg-icons';
import { NotificationStatusEnum, PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Indicator, Navbar } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { userService } from '../../reducers/permissions/userSlice';
import { notificationService } from '../../reducers/shared/notificationSlice';
import { workspaceService } from '../../reducers/workspaces/workspaceSlice';
import { useAppDispatch, useTypedSelector } from '../../stateStore';
import { AuthHelper } from '../../utils/auth/authHelper';
import IconButton from '../IconButton';
import ProtectElement from '../ProtectElement';
import UserProfileMini from '../auth/UserProfileMini';
import AppHeadingWithBreadcrumbs from './AppHeadingWithBreadcrumbs';

export interface IAppHeaderV2Props {
  hasBreadcrumbs?: boolean;
  hasLogo?: boolean;
  hasMenuToggle?: boolean;
  hasUserProfile?: boolean;
  initialCrumb?: string;
  onMenuToggle?: () => void;
}

const AppHeaderV2 = ({
  hasBreadcrumbs,
  hasLogo = true,
  hasMenuToggle = true,
  hasUserProfile = true,
  initialCrumb,
  onMenuToggle,
}: IAppHeaderV2Props) => {
  const dispatch = useAppDispatch();
  const [isUnread, setIsUnread] = useState<boolean>(false);

  const notifications = useTypedSelector(
    notificationService.selectors.notifications,
  );

  const workspace = useTypedSelector(workspaceService.selectors.workspace);
  const userMe = useTypedSelector(userService.selectors.userMe);

  const isAuthenticated = useMemo(() => userMe, [userMe]);

  const handleResetWorkspace = () => {
    dispatch(workspaceService.actions.resetSingleWorkspace());
  };

  useEffect(() => {
    if (
      !userMe ||
      !AuthHelper.forUser(userMe).currentUserHasPermission(
        PermissionEnum.ViewNotificationFeed,
      )
    )
      return;

    // Request notifications from the API, so we can show the unread notification indicator
    dispatch(
      notificationService.findAllPaginated({
        userId: userMe?.id,
      }),
    );
  }, [dispatch, userMe]);

  useEffect(() => {
    if (!notifications) return;

    // Show the unread indicator if any notifications are not read
    setIsUnread(
      notifications?.some(
        (notification) =>
          notification.notificationStatus !== NotificationStatusEnum.Read,
      ),
    );
  }, [notifications]);

  const manageWorkspacesBreadcrumbs = window.location.href.includes(
    'manage-workspaces',
  )
    ? true
    : hasBreadcrumbs;

  return (
    <Navbar
      id="nav-header"
      className="flex justify-between items-center h-[70px] px-5 sm:px-8 py-2.5 bg-sr-black border-l border-[#565656]"
    >
      <Navbar.Start className="gap-2">
        {hasMenuToggle && (
          <IconButton
            label="Menu"
            className="text-white lg:hidden"
            icon={faNavicon}
            iconClassName="w-[18px] h-[18px]"
            iconSize="2x"
            tipPosition="right"
            onClick={() => onMenuToggle?.()}
          />
        )}
        <AppHeadingWithBreadcrumbs
          hasBreadcrumbs={manageWorkspacesBreadcrumbs}
          initialCrumb={initialCrumb}
          userType={userMe?.userType}
          workspaceName={workspace?.workspaceName}
          onResetWorkspace={handleResetWorkspace}
        />
      </Navbar.Start>
      {hasLogo && (
        <Navbar.Center>
          <Link to="/dashboard">
            <img
              alt="Screenrights"
              className="h-8"
              src="/screenrights-logo.svg"
            />
          </Link>
        </Navbar.Center>
      )}
      {isAuthenticated && hasUserProfile && (
        <Navbar.End className="gap-1.5">
          <div className="flex w-12 h-12 justify-center items-center gap-2.5">
            <ProtectElement
              permissionRequired={[PermissionEnum.ViewNotificationFeed]}
            >
              <Link to="/profile/notifications">
                <Indicator>
                  {isUnread && (
                    <Badge
                      className="!top-4 !right-[1.2rem] indicator-item indicator-top indicator-end badge-xs"
                      color="error"
                    />
                  )}
                  <IconButton
                    label="Notifications"
                    className="p-2 text-white"
                    icon={faBell}
                    iconClassName="w-6 h-6"
                    iconColor="accent"
                    iconSize="2x"
                    tipPosition="bottom"
                  />
                </Indicator>
              </Link>
            </ProtectElement>
          </div>
          <div className="flex items-center gap-1.5 py-1.5">
            <UserProfileMini />
          </div>
        </Navbar.End>
      )}
    </Navbar>
  );
};

export default AppHeaderV2;
