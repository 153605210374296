import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  MembershipsApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { MembershipSliceState } from './membershipState';

// Function for creating an instance of the UsersApi class
const GetMembershipsApi = () => {
  return new MembershipsApi(
    new Configuration(),
    configuration.WORKSPACES_API_BASE,
    axiosInstance,
  );
};

const findOne = createAsyncThunk(
  'membership/findOne',
  async (id: string, thunkApi) => {
    try {
      const result = await GetMembershipsApi().membershipControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialMembershipState: MembershipSliceState = {
  isLoading: ApiLoadingStateEnum.idle,
  isMembershipLoading: ApiLoadingStateEnum.idle,
  isMembershipUpdating: ApiLoadingStateEnum.idle,
  memberships: [],
};

const membershipsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindOne
      .addCase(findOne.fulfilled, (state, action) => {
        state.isMembershipLoading = ApiLoadingStateEnum.succeeded;
        state.membership = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isMembershipLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isMembershipLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialMembershipState,
  name: 'membership',
  reducers: {
    reset: () => {
      return {
        ...initialMembershipState,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialMembershipState.errorResponse,
      };
    },
  },
});

const membershipThunk = {
  findOne,
};

const membershipSelectors = {
  errorResponse: (state: RootState) => state.memberships.errorResponse,
  isLoading: (state: RootState) => state.memberships.isLoading,
  isMembershipLoading: (state: RootState) =>
    state.memberships.isMembershipLoading,
  isMembershipUpdating: (state: RootState) =>
    state.memberships.isMembershipUpdating,
  membership: (state: RootState) => state.memberships.membership,
  memberships: (state: RootState) => state.memberships.memberships,
  paginationMeta: (state: RootState) => state.memberships.paginationMeta,
};

export const membershipService = {
  ...membershipThunk,
  actions: membershipsSlice.actions,
  selectors: membershipSelectors,
};

export default membershipsSlice.reducer;
