import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  Config,
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import thunkMiddleware from 'redux-thunk';
// List of states
import {
  activityReducer,
  activityStatementsReducer,
  agreementReducer,
  airwallexReducer,
  bankDetailsReducer,
  cascadeReducer,
  clientReducer,
  globalReducer,
  invitationReducer,
  invoiceReducer,
  membershipReducer,
  notificationReducer,
  paymentRuleReducer,
  payoutReducer,
  saleReducer,
  serviceAccessRoleReducer,
  settingReducer,
  titleReducer,
  userReducer,
  userRoleReducer,
  workspaceReducer,
} from './reducers';

export const allReducers = {
  activity: activityReducer,
  activityStatements: activityStatementsReducer,
  agreements: agreementReducer,
  airwallex: airwallexReducer,
  bankDetails: bankDetailsReducer,
  cascades: cascadeReducer,
  clients: clientReducer,
  globalReducer: globalReducer,
  invitations: invitationReducer,
  invoices: invoiceReducer,
  memberships: membershipReducer,
  notifications: notificationReducer,
  paymentRules: paymentRuleReducer,
  payouts: payoutReducer,
  sales: saleReducer,
  serviceAccessRole: serviceAccessRoleReducer,
  settings: settingReducer,
  titles: titleReducer,
  userRole: userRoleReducer,
  users: userReducer,
  workspaces: workspaceReducer,
  // Add your slice reducers here
};

const config: Config = {
  predicate: (action) => {
    const type: string = action.type;

    return type.includes('users/');
  },
};

const stateStore = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      thunkMiddleware,
      // Add state sync middleware to ensure tabs are consistent
      createStateSyncMiddleware(config),
    ),
  reducer: allReducers,
});

// We want new tabs to share state
initMessageListener(stateStore);

export type RootState = ReturnType<typeof stateStore.getState>;
export type AppDispatch = typeof stateStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default stateStore;
